
@keyframes fadeIn {
    from {
        opacity: 0;
    }
}


.loading-container {
    background-color: #f0f2f5;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h5 {
        animation: fadeIn 0.6s infinite alternate;
        color: var(--ant-color-primary);
    }
}

.loading-subpage-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
